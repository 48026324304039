import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import statisticsStoreModule from '@/views/dashboard/statisticsStoreModule'
import UsersStoreModule from '@/views/iscritti/iscritti/UsersStoreModule'
import ProdottiStoreModule from '@/views/prodotti/prodotti/ProdottiStoreModule'
import eCommerceStoreModule from '@/views/e-commerce/eCommerceStoreModule'
import OrdersStoreModule from '@/views/ordini/OrdersStoreModule'
import VpcsStoreModule from '@/views/provvigioni/VpcsStoreModule'
import CommissionsStoreModule from '@/views/commissioni-richieste/CommissionsStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users: UsersStoreModule,
    items: ProdottiStoreModule,
    statistics: statisticsStoreModule,
    ecommerce: eCommerceStoreModule,
    orders: OrdersStoreModule,
    vpcs: VpcsStoreModule,
    commissions: CommissionsStoreModule,
  },
  strict: process.env.DEV,
})
