export default [
  {
    path: '/iscritti/:filter?',
    name: 'iscritti.list',
    component: () => import('@/views/iscritti/iscritti/Users.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Users',
      action: 'index',
    },
  },
  {
    path: '/albero/:filter?',
    name: 'iscritti.tree',
    component: () => import('@/views/iscritti/iscritti/iscritti-tree/UsersTree'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Users',
      action: 'index',
    },
  },
  // {
  //   path: '/iscritti/store',
  //   name: 'iscritti.store',
  //   component: () => import('@/views/iscritti/iscritti/iscritti-create/UserStore.vue'),
  //   meta: {
  //     resource: 'Users',
  //     action: 'store',
  //   },
  // },
  {
    path: '/iscritti/edit/:id',
    name: 'iscritti.edit',
    component: () => import('@/views/iscritti/iscritti/iscritti-edit/UserEdit.vue'),
    meta: {
      resource: 'Users',
      action: 'update',
    },
  },
]
