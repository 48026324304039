import axios from '@axios'

export default {
  namespaced: true,
  state: {
    provvigioni: [],
  },
  getters: {
    getListProvvigioni: state => state.provvigioni,
  },
  mutations: {
    setListProvvigioni: (state, provvigioni) => {
      state.provvigioni = provvigioni
    },
  },
  actions: {
    fetchProvvigioni(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('provvigioni', { params: queryParams })
          .then(response => {
            ctx.commit('setListProvvigioni', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProvvigione(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`provvigioni/${id}`)
          .then(response => {
            // ctx.commit('setCurrentOrdine', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
