export default [
  // *===============================================---*
  // *--------- Commissioni  ---------------------------*
  // *===============================================---*
  {
    path: '/commissioni-richieste',
    name: 'commissioni-richieste.list',
    component: () => import('@/views/commissioni-richieste/Commissions.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Commissions',
      action: 'index',
    },
  },
]
