import axios from '@axios'

export default {
  namespaced: true,
  state: {
    statistics: [],
  },
  getters: {
    getStatistics: state => state.statistics,
  },
  mutations: {
    setStatistics: (state, statistics) => {
      state.statistics = statistics
    },
  },
  actions: {
    fetchStatistics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/statistiche', { params: queryParams })
          .then(response => {
            ctx.commit('setStatistics', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
