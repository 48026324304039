// import useJwt from '@/auth/jwt/useJwt'
import CryptoJS from 'crypto-js'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem('accessToken')
}

export const getUserData = () => JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData'), process.env.VUE_APP_CRYPT).toString(CryptoJS.enc.Utf8))

export const logout = () => {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Reset ability
  ability.update(initialAbility)

  store.commit('statistics/setStatistics', [])

  // Redirect to login page
  router.replace({ name: 'auth-login' })
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/dashboard'
  if (userRole === 'client') return '/dashboard'
  return '/dashboard'
}
