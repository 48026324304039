import axios from '@axios'

export default {
  namespaced: true,
  state: {
    prodotti: [],
  },
  getters: {
    getListProdotti: state => state.prodotti,
  },
  mutations: {
    setListProdotti: (state, prodotti) => {
      state.prodotti = prodotti
    },
  },
  actions: {
    fetchProdotti(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('items', { params: queryParams })
          .then(response => {
            ctx.commit('setListProdotti', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProdotto(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`items/${id}`)
          .then(response => {
            ctx.commit('setCurrentProdotto', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addProdotto(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('items', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateProdotto(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`items/${data.get('id')}`, data, { // Imposto la chiamata in post per far funzionare fomData e viene convertita in put dalla configurazione
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }) // Qui uso get('id') perché data è un oggetto formData
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteProdotto(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`items/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteProdotto(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`items/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
