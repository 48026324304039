export default [
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/shop',
    name: 'shop.list',
    component: () => import('@/views/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Items',
      action: 'index',
    },
  },
  {
    path: '/checkout',
    name: 'shop.checkout',
    component: () => import('@/views/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Orders',
      action: 'store',
    },
  },
  // {
  //   path: '/iscritti/store',
  //   name: 'iscritti.store',
  //   component: () => import('@/views/iscritti/iscritti/iscritti-create/UserStore.vue'),
  //   meta: {
  //     resource: 'Users',
  //     action: 'store',
  //   },
  // },
  // {
  //   path: '/prodotti/edit/:id',
  //   name: 'prodotti.edit',
  //   component: () => import('@/views/prodotti/prodotti/prodotti-edit/ProdottiEdit.vue'),
  //   meta: {
  //     resource: 'Items',
  //     action: 'update',
  //   },
  // },
]
