export default [
  // *===============================================---*
  // *--------- ORDINI  ---------------------------------------*
  // *===============================================---*
  {
    path: '/ordini',
    name: 'ordini.list',
    component: () => import('@/views/ordini/Orders.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Orders',
      action: 'index',
    },
  },
]
