export default [
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/Privacy.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
