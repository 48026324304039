// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    iscritti: [],
    treeIscritti: [],
    draftIscritto: [],
  },
  getters: {
    getListIscritti: state => state.iscritti,
    getTreeListIscritti: state => state.treeIscritti,
    getDraftIscritto: state => state.draftIscritto,
  },
  mutations: {
    setListIscritti: (state, iscritti) => {
      state.iscritti = iscritti
    },
    setTreeListIscritti: (state, iscritti) => {
      state.treeIscritti = iscritti
    },
    addDraftItem: (state, iscritto) => {
      state.draftIscritto = iscritto
    },
  },
  actions: {
    fetchIscritti(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('users', { params: queryParams })
          .then(response => {
            ctx.commit('setListIscritti', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTreeIscritti(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('users', { params: queryParams })
          .then(response => {
            ctx.commit('setTreeListIscritti', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchIscritto(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`users/${id}`)
          .then(response => {
            // ctx.commit('setCurrentIscritto', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addIscritto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('users', userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateIscritto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`users/${userData.id}`, userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteIscritto(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`users/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteIscritto(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`users/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resetPassword(ctx, email) {
      return new Promise((resolve, reject) => {
        axios
          .post('resetpassword', email)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setupIntent() {
      return new Promise((resolve, reject) => {
        axios
          .get('setup-intent')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addPaymentMethod(ctx, method) {
      return new Promise((resolve, reject) => {
        axios
          .post('payment-methods', method)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPaymentMethods() {
      return new Promise((resolve, reject) => {
        axios
          .get('payment-methods')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSubscriptions() {
      return new Promise((resolve, reject) => {
        axios
          .get('subscriptions')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createSubscription(ctx, subscriptionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('subscriptions', subscriptionData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    updateSubscription(ctx, subscriptionData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`subscriptions/${subscriptionData.id}`, subscriptionData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteSubscription(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`subscriptions/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    fetchFiles() {
      return new Promise((resolve, reject) => {
        axios
          .get('files')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    downloadFiles(ctx, file) {
      return new Promise((resolve, reject) => {
        axios
          .get('download', file)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
