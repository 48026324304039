export default [
  // *===============================================---*
  // *--------- ORDINI  ---------------------------------------*
  // *===============================================---*
  {
    path: '/provvigioni',
    name: 'provvigioni.list',
    component: () => import('@/views/provvigioni/Provvigioni.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Vpcs',
      action: 'index',
    },
  },
]
