export default [
  {
    path: '/prodotti/:filter?',
    name: 'prodotti.list',
    component: () => import('@/views/prodotti/prodotti/Prodotti.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Items',
      action: 'store',
    },
  },
  // {
  //   path: '/iscritti/store',
  //   name: 'iscritti.store',
  //   component: () => import('@/views/iscritti/iscritti/iscritti-create/UserStore.vue'),
  //   meta: {
  //     resource: 'Users',
  //     action: 'store',
  //   },
  // },
  // {
  //   path: '/prodotti/edit/:id',
  //   name: 'prodotti.edit',
  //   component: () => import('@/views/prodotti/prodotti/prodotti-edit/ProdottiEdit.vue'),
  //   meta: {
  //     resource: 'Items',
  //     action: 'update',
  //   },
  // },
]
