import axios from '@axios'
import CryptoJS from 'crypto-js'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = localStorage.getItem('userData') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData'), process.env.VUE_APP_CRYPT)
        .toString(CryptoJS.enc.Utf8)) : null
      return userData && userData.extras ? userData.extras.shop_cart_items_count : 0
    })(),
    deliverAddress: null,
    itemsInCart: null,
  },
  getters: {
    getCurrentItemsInCart: state => state.itemsInCart,
  },
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
      const userData = localStorage.getItem('userData') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData'), process.env.VUE_APP_CRYPT)
        .toString(CryptoJS.enc.Utf8)) : null
      if (userData !== null) {
        userData.extras.shop_cart_items_count = count
        localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(userData), process.env.VUE_APP_CRYPT).toString())
      }
    },
    UPDATE_DELIVER_ADDRESS(state, deliverAddress) {
      state.deliverAddress = deliverAddress
    },
    UPDATE_ITEMS_IN_CART(state, itemsInCart) {
      state.itemsInCart = itemsInCart
    },
  },
  actions: {
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('items', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('orders', { params: { cart: true } })
          .then(response => {
            resolve(response)
            ctx.commit('UPDATE_ITEMS_IN_CART', response.data.data.items)
          })
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('orders', item)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('orders', item)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    paymentSession(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('orders', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    paymentIntent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('payment-intent', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
