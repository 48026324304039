import axios from '@axios'

export default {
  namespaced: true,
  state: {
    ordini: [],
  },
  getters: {
    getListOrdini: state => state.ordini,
  },
  mutations: {
    setListOrdini: (state, ordini) => {
      state.ordini = ordini
    },
  },
  actions: {
    fetchOrdini(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('orders', { params: queryParams })
          .then(response => {
            ctx.commit('setListOrdini', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchOrdine(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`orders/${id}`)
          .then(response => {
            // ctx.commit('setCurrentOrdine', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateOrdine(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`orders/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteOrdine(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`orders/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteOrdine(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`orders/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
