import axios from '@axios'

export default {
  namespaced: true,
  state: {
    commissioni: [],
  },
  getters: {
    getListCommissioni: state => state.commissioni,
  },
  mutations: {
    setListCommissioni: (state, commissioni) => {
      state.commissioni = commissioni
    },
  },
  actions: {
    fetchCommissioni(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('commissions', { params: queryParams })
          .then(response => {
            ctx.commit('setListCommissioni', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCommissione(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`commissions/${id}`)
          .then(response => {
            // ctx.commit('setCurrentOrdine', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addRichiestaCommissione(ctx, Data) {
      return new Promise((resolve, reject) => {
        axios
          .post('commissions', Data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCommissione(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`commissions/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteCommissione(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`commissions/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteCommissione(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`commissions/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
