export default [
  // *===============================================---*
  // *--------- ORDINI  ---------------------------------------*
  // *===============================================---*
  {
    path: '/area-personale',
    name: 'area-personale',
    component: () => import('@/views/utente-loggato/account-setting/AccountSetting'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'User',
      action: 'update',
    },
  },
]
